import * as React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Welcome from "../components/welcome"
import Quality from "../components/quality"
import About from "../components/about"
import Features from "../components/features"
import Products from "../components/products"
import Personalize from "../components/personalize"
import Try from "../components/try"
import Contact from "../components/contact"
import Header from "../components/header.sk"

import * as Translation from "../locales/sk.json";


const query2 = graphql`
  query {
    allStrapiProdukt(filter: {locale: {eq: "sk"}}) {
      edges {
        node {
          id
          category {
            id
          }
          Nazwa
          PDF {
            localFile {
              publicURL
            }
          }
          Image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    strapiFrontpage(locale: {eq: "sk"}) {
      hero_text
      Welcome_left
      Welcome_right
      Values
      Personalization_text
      Try_text
      Personalization_image {
        localFile {
          publicURL
        }
      }
      Contact_text
      Try_image {
        localFile {
          childImageSharp {
            fluid(maxWidth: 774) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
          publicURL
        }
      }
      categories {
        id
        Text
        Type
        Features_title
        Features {
          Text
          To_right
          Top
          Left
        }
        Movie {
          localFile {
            publicURL
          }
        }
        Images {
          localFile {
            publicURL
          }
        }
        Features_image {
          localFile {
            publicURL
          }
        }
      }
      hero_image {
        localFile {
          publicURL
        }
      }
    }
  }
`;
const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Header />
    <StaticQuery query={query2} render={(data) => (
      <div>
        <Hero translation={Translation} text={data.strapiFrontpage.hero_text} image={data.strapiFrontpage.hero_image.localFile.publicURL} />
        <Welcome textLeft={data.strapiFrontpage.Welcome_left} textRight={data.strapiFrontpage.Welcome_right} />
        <Quality values={data.strapiFrontpage.Values} />
        {data.strapiFrontpage.categories.sort(c => -1).map((value) => {
          const products = data.allStrapiProdukt.edges.filter(x => { return x.node.category.id === value.id});
          return (<div><About translation={Translation} movie={value.Movie?.localFile.publicURL} text={value.Text} type={value.Type} images={value.Images} /> <Features translation={Translation} features={value.Features} title={value.Features_title} type={value.Type} image={value.Features_image} /> <Products translation={Translation} type={value.Type} products={products} /></div>)
        })}
        <Personalize translation={Translation} text={data.strapiFrontpage.Personalization_text} image={data.strapiFrontpage.Personalization_image.localFile.publicURL} />
        <Try translation={Translation} text={data.strapiFrontpage.Try_text} image={data.strapiFrontpage.Try_image.localFile.childImageSharp.fluid} />
        <Contact translation={Translation} text={data.strapiFrontpage.Contact_text} />
      </div>
    )} />
  </Layout>
)

export default IndexPage
